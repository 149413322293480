import React from 'react';
import './style.scss';
import { graphql, StaticQuery, Link } from 'gatsby';
// import AudioPlayer from "react-h5-audio-player";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";

const PodcastsResources = ({props}) => {
    
  /*   */

    return(
        
        <div className="blockwrap">
            <StaticQuery query={graphql`
                {
                    allWordpressWpPodcast (limit: 2) {
                        edges {
                          node {
                            id
                            wordpress_id
                            date(formatString: "MMMM Do, YYYY")
                            slug
                            title
                            excerpt
                            author {
                              name
                            }
                            content
                            template
                            link
                            path
                            acf {
                              desktop_featured_image {
                                localFile {
                                  childImageSharp {
                                    fluid(maxWidth: 2000) {
                                      sizes
                                      src
                                      srcSet
                                      aspectRatio
                                    }
                                  }
                                }
                              }
                              podcast_url
                              
                            }
                            featured_media {
                              localFile {
                                childImageSharp {
                                  fluid(maxWidth: 750) {
                                    sizes
                                    src
                                    srcSet
                                    aspectRatio
                                  }
                                }
                              }
                            }
                          }
                        }
                      }      
                } 
            `
            } render={(props) => {

                
                console.log('Advocates props ',props.allWordpressWpPodcast.edges);
                //let deskImg = <Img fluid={props.allWordpressWpCommunityBlog.edges[0].node.acf.desktop_featured_image.localFile.childImageSharp.fluid} />;
                //let mobImg  = <Img fluid={props.allWordpressWpCommunityBlog.edges[0].node.featured_media.localFile.childImageSharp.fluid} />;
                const featuredPages = props.allWordpressWpPodcast.edges.map(featPage => {
                    let page = featPage.node;
                    const Player = () => (
                        <AudioPlayer
                            className="player"
                            src={page.acf.podcast_url}
                            onPlay={e => console.log("onPlay")}
                            // other props here
                        />
                    );
                    return (
                            <li key={page.wordpress_id}>
                                <div className="info">
                                    <h3 dangerouslySetInnerHTML={{__html: page.title}}></h3>
                                    <div className="player-wrap">
                                        <Player />
                                    </div>
                                    
                                </div>
                            </li>
                        )
                })
            
                return(
                  
                    <div className="feat-podcasts-wrap">
                       
                        <ul className="podcasts-resources">
                            {featuredPages}
                        </ul>

                        <Link to="/resources/podcasts" className="all-podcasts btn-basic">Browse All <i className="fa fa-long-arrow-right"></i></Link>
                    
                    </div>
                    
                )

          }} />
        </div>
        
    )

}
export default PodcastsResources;