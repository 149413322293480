import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import Img from "gatsby-image"
import FeaturedImage from '../../components/FeaturedImage/FeaturedImage';
import SEO from '../../components/seo';
//import EmailSignUp from '../../components/Forms/EmailSignUp/EmailSignUp';
import PodcastsResources from '../../components/AdvocatesResources/PodcastsResources/PodcastsResources';
import Columns from '../../components/Columns/Columns';
// import AudioPlayer from "react-h5-audio-player";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";



const AdvocatesResources = ({pageContext}) => {

    //Printable Resources
    let printableResources;
    if(pageContext.acf.pdf_files !== undefined){
         printableResources = pageContext.acf.pdf_files.map((pdf) => {

            return (
                <li><a href={pdf.file.source_url} rel="noopener noreferrer">{pdf.file_name}</a></li>
            )
        })
    }

    //Online Resources
    let onlineResources;
    if(pageContext.acf.pdf_files !== undefined){
        onlineResources = pageContext.acf.add_links.map((link) => {

            return (
                <li><a href={link.add_link} target="_blank" rel="noopener noreferrer">{link.name}</a></li>
            )
        })
    }

    // Meditations
    let meditations;
    if(pageContext.acf.add_meditations !== undefined){
            meditations = pageContext.acf.add_meditations.map((file) => {
                const Player = () => (
                    <AudioPlayer
                        className="player"
                        src={file.audio_url}
                        onPlay={e => console.log("onPlay")}
                        // other props here
                    />
                )

            return (
                <li>
                    <h3>{file.file_name}</h3>
                    <Player />
                </li>
            )
        })
    }

    // LABL RECORDINGS
    let labl_recordings;
    if(pageContext.acf.live_a_brighter_life_recordings !== undefined){
        labl_recordings = pageContext.acf.live_a_brighter_life_recordings.map((file) => {
                const Player = () => (
                    <AudioPlayer
                        className="player"
                        src={file.audio_url}
                        onPlay={e => console.log("onPlay")}
                        // other props here
                    />
                )
                
            return (
                <li>
                    <div className="title-holder"><h3>{file.file_name}</h3></div>
                    <div className="img-wrapper"><Img fluid={file.featured_image.localFile.childImageSharp.fluid} /></div>
                    <Player />
                </li>
            )
        })
    }

    return (
        <Wrapper pageCurrent={ `advocates-resources ${pageContext.title.toLowerCase()}` }>
            <SEO title={pageContext.title} />
            { console.log('pageContext ',pageContext)}

            <div className="blockwrap menu-spacer">
                <FeaturedImage pageContext={pageContext} /> 
            </div>

            <div className="blockwrap">
                
                <div className="container base-styles stripe">
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline">{pageContext.title}</h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                       
                    </div> 
                </div>
            
            </div>
            <div className="blockwrap">
                <div className="container base-styles">
                    <div className="content">
                        <hr className="wp-block-separator" />
                        <Columns>
                        <div className=" ">
                            <h2>Printable Resources</h2>
                            <ul className="resources">
                                {printableResources}
                            </ul>
                        </div>
                        <div className="">
                            <h2>Online Resources</h2>
                            <ul className="resources">
                                {onlineResources}
                            </ul>
                        </div>
                        </Columns>
                    </div>
                </div>
            </div>
            <div className="blockwrap">
                <div className="container base-styles feat-podcasts">
                    <div className="content">
                        <hr className="wp-block-separator" />
                        <h2>Podcasts</h2>
                        <p>Explore different methods of improving your well-being through our podcasts.</p>
                        <PodcastsResources />
                    </div>
                </div>
            </div>

            <div className="blockwrap">
                <div className="container base-styles">
                    <div className="content">
                        <hr className="wp-block-separator" />
                        <h2>Meditations</h2>
                        <ul className="mediations">
                            {meditations}
                        </ul>
                        
                    </div>
                </div>
            </div>

            <div className="blockwrap">
                <div className="container base-styles labl">
                    <div className="content">
                    <hr className="wp-block-separator" />
                    <h2>Live A Brighter Life Recordings</h2>
                    <ul className="labl-recordings">
                        {labl_recordings}
                    </ul>
                    </div>
                </div>
            </div>
            
           
               
        </Wrapper>
    )

}
export default AdvocatesResources;